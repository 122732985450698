import React from "react";
import { graphql } from "gatsby";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core/index";
import Layout from "../components/element/layout/Layout";
import PageBanner from "../components/element/page-element/PageBanner";
import LayoutSetBackground from "../components/element/layout/LayoutSetBackground";
import theme, { ooTextWidth, grey8 } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import SEO from "../components/element/SEO";
// import PrivacyExplainer from "./PrivacyExplainer";


const privacySubheading = "";
const seo = {
  title: "Privacy Policy - Oberion",
  description: null,
};

function Privacy(props) {
  const { data: { legal: { edges } } } = props;
  const { node: { childMarkdownRemark: { frontmatter, html } } } = edges[0];


  // let privacyExplainer = null; // eslint-disable-line
  if (frontmatter.title !== "Privacy Policy") {
    throw new Error(
      "Error with 'privacy.md'. GraphQL query did not deliver expected results. Check markup and template file for consistency!",
    );
  }
  const pageBanner = (<PageBanner heading={frontmatter.title} />);
  const lastUpdate = frontmatter.date;

  return (
    <Layout hideNewsletter>
      <SEO {...seo} />
      {pageBanner}

      <LayoutSetBackground backgroundColor={grey8} color={theme.palette.secondary.contrastText}>
        <LayoutColumn justifyContent="center"
                      textAlign="left"
                      maxWidth={ooTextWidth}>
          {/*{privacyExplainer}*/}

          <Typography component="span" color="inherit">
            <div
              className="legal-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            {lastUpdate}

          </Typography>


        </LayoutColumn>
      </LayoutSetBackground>


    </Layout>
  );
}

// noinspection JSUnusedGlobalSymbols
export const privacyQuery = graphql`
{
  legal: allFile(
    filter: {
      internal: {
        mediaType: {eq: "text/markdown"}}, 
      name: {eq: "privacy-policy"}, 
      sourceInstanceName: {eq: "legal"}}) {
    edges {
      node {
        childMarkdownRemark {
          html
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  }
}`;

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
};
export default Privacy;